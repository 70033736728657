import AMapLoader from '@amap/amap-jsapi-loader';
let securityJsCode = 'a68be460d478eb70d5a29be8f15cbaa8'  //申请好的安全码
let key = "dd85d080c75fc69435e92d391534782d"  //申请好的Web端开发者Key

export const mapInit = async () => {
    window._AMapSecurityConfig = {
        securityJsCode: securityJsCode
    }
    return AMapLoader.load({
        "key": key,              // 申请好的Web端开发者Key，首次调用 load 时必填
        "version": "2.0",   // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
        "plugins": [],           // 需要使用的的插件列表，如比例尺'AMap.Scale'等
    })
}

//匹配推荐地址
export const fetchPosOptions = async (city = '大连市', keyword = '') => {
    return new Promise(async (resolve) => {
        const AMap = await mapInit()
        AMap.plugin('AMap.AutoComplete', () => {
            // 实例化Autocomplete
            var autoOptions = {
                city: city
            }
            var autoComplete = new AMap.Autocomplete(autoOptions);
            autoComplete.search(keyword, (status, result) => {
                // 搜索成功时，result即是对应的匹配数据
                resolve(result.tips)
            })
        })
    })
}

//按城市名找默认定位
export const fetchCityLngLat = (city = '大连市') => {
    return new Promise(async (resolve) => {
        const AMap = await mapInit()
        //按城市名找默认定位
        AMap.plugin('AMap.DistrictSearch', () => {
            const districtSearch = new AMap.DistrictSearch()
            districtSearch.search(city, (status, result) => {
                resolve(new AMap.LngLat(result.districtList[0].center.lng, result.districtList[0].center.lat))
            })
        })
    })
}
 