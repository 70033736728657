<template>
  <div id="body">
    <div class="fatherBox">
      <div class="headerBox">
        <p>门店列表</p>
        <el-button type="primary" class="btn" @click="dialogFormVisible = true">+ 添加门店</el-button>
        <!-- @click="add()" -->
      </div>
      <div class="query">
        <div class="queryLeft">
          <div style="width: auto;">区：
            <el-select class="select" filterable v-model="serchList.area_id" size="medium" placeholder="请选择区"
              style="width: auto;" @change="areaSelect">
              <el-option v-for="item in area_data" :key="item.id" :label="item.name" :value="item.id">
              </el-option>
            </el-select>
          </div>
          <div style="width: auto;">&nbsp;门店点：
            <el-select class="select" filterable v-model="serchList.store_id" size="medium" placeholder="请选择门店"
              style="width: auto;" @change="shopSelect">
              <el-option v-for="item in shopData" :key="item.id" :label="item.shopName" :value="item.id"
                :area="item.area">
              </el-option>
            </el-select>
          </div>
        </div>
        <div class="queryRight">
          <el-button @click="reset()">重置</el-button>
          <el-button type="primary" @click="storeListFunSearch(serchList,1)">查询</el-button>
        </div>
      </div>

      <el-table :data="shopInfos" v-loading="loading">
        <el-table-column prop="serial" label="序号" type="index" align="center" width="100">
          <template slot-scope="scope">
            {{ (currpage - 1) * pagesize + scope.$index + 1 }}
          </template>
        </el-table-column>
        <el-table-column prop="shop_name" label="名店名称" align="center" width="auto">
        </el-table-column>
        <el-table-column prop="ssq" label="门店位置" align="center" width="auto">
          <template slot-scope="scope">
            {{scope.row.pname}}{{scope.row.cname}}
            {{scope.row.aname}}{{scope.row.addr}}{{scope.row.detail_addr }}
          </template>
        </el-table-column>
        <el-table-column prop="shops_num" label="员工数量" align="center" width="auto"></el-table-column>
        <el-table-column prop="auth_num" label="授权用户" align="center" width="auto"></el-table-column>
        <el-table-column prop="create_time" label="加入时间" width="160" align="center">
        </el-table-column>
        <el-table-column label="操作" fixed="right" align="center" width="120">
          <template slot-scope="scope">
            <el-button size="mini" type="primary" plain v-if="scope.row.TeaName==null?true:false"
              @click="detail(scope.$index, scope.row)">门店详情</el-button>
            <el-button size="mini" type="primary" plain v-if="scope.row.TeaName==null?true:false"
                @click="edit(scope.$index, scope.row)">修改门店</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="block" v-show="CountNum > 0">
        <el-pagination @current-change="handleCurrentChange" :page-size="pagesize" background
          layout="total,prev, pager, next, jumper" :current-page="currpage" :total="CountNum" style="margin-top:20px">
        </el-pagination>
      </div>
    </div>

    <el-dialog title="添加门店" :visible.sync="dialogFormVisible" top="50px" :close-on-click-modal="false">
      <el-form ref="form" :model="formAdd">
        <el-form-item label="门店名称">
          <el-input v-model="formAdd.store_name" placeholder="请填写门店名称" style="width: auto;"></el-input>
        </el-form-item>

        <el-form-item label="所在区县">
          <el-select class="select" v-model="formAdd.area_id" size="medium" placeholder="请选择区" style="width: auto;">
            <el-option v-for="item in area_data_add" :key="item.id" :label="item.name" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="门店位置">
          <map-position @update="mapChange" />
          <!--          <el-select class="select"  v-model="formAdd.store_addr" size="medium" placeholder="请选择区"
            style="width: auto;">
            <el-option v-for="item in area_data_add" :key="item.id" :label="item.name" :value="item.id">
            </el-option>
          </el-select> -->
        </el-form-item>

        <el-form-item label="详细地址">
          <el-input v-model="formAdd.detail_addr" placeholder="请填写详细地址" style="width: auto;"></el-input>
        </el-form-item>

        <el-form-item label="联系人">
          <el-input v-model="formAdd.contact" placeholder="请填写联系人" style="width: auto;"></el-input>
        </el-form-item>

        <el-form-item label="联系电话">
          <el-input v-model="formAdd.contact_tel" placeholder="请填写联系电话" style="width: auto;"></el-input>
        </el-form-item>

        <el-form-item label="门店照片">
          <el-upload ref="upload" :action="imgurl" list-type="picture-card" :limit="1" :on-progress="uploadingShop"
            :file-list="shopImgList" accept="image/jpg,image/png,image/jpeg" :on-remove="removeImgShop"
            :headers="{'token':token}" :on-success="fileSuccessShop" :on-error="imgErrorShop"
            :on-exceed="limitAlertShop" style="height: 145px;">
            <i slot="default" class="el-icon-plus"></i>
          </el-upload>
        </el-form-item>

        <el-form-item label="营业时间">
          <el-time-select placeholder="开始时间" v-model="formAdd.startTime" :picker-options="{start: '00:00', step: '00:10', end: '23:50'}">
          </el-time-select>
          <el-time-select placeholder="结束时间" v-model="formAdd.endTime" :picker-options="{
            start: '00:00',step: '00:10',end: '23:50'}">
          </el-time-select>
        </el-form-item>


        <el-form-item label="公司名称">
          <el-input v-model="formAdd.company_name" placeholder="请填写公司名称"  style="width: auto;"></el-input>
        </el-form-item>

        <el-form-item label="公司类型">
          <el-select class="select" v-model="formAdd.document_type" size="medium" placeholder="请选择公司类型"
            style="width: auto;">
            <el-option v-for="item in zjType" :key="item.id" :label="item.identity_name" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="所在行业">
          <el-cascader v-model="formAdd.industry" size="medium" placeholder="请选择所在行业" style="width: auto;"
            :options="hyList" :show-all-levels="false" @change="hyChange"></el-cascader>
        </el-form-item>

        <el-form-item label="证件照片">
          <el-upload ref="upload" :action="imgurl" list-type="picture-card" :limit="1" :on-progress="uploadingCom"
            :file-list="comImgList" accept="image/jpg,image/png,image/jpeg" :on-remove="removeImgCom"
            :headers="{'token':token}" :on-success="fileSuccessCom" :on-error="imgErrorCom" :on-exceed="limitAlertCom"
            style="height: 145px;">
            <i slot="default" class="el-icon-plus"></i>
          </el-upload>
        </el-form-item>

        <el-form-item label="统一社会信用代码">
          <el-input v-model="formAdd.organization_code" placeholder="请填写统一社会信用代码"  style="width: auto;"></el-input>
        </el-form-item>

        <el-form-item label="证件有效期">
          <el-date-picker v-model="formAdd.document_validity" value-format="yyyy-MM-dd" type="date" placeholder="选择日期">
          </el-date-picker>
        </el-form-item>

        <el-form-item label="注册地">
          <el-select v-model="province" class="select" clearable @change="provincese">
            <el-option v-for="item in province_data" :key="item.id" :label="item.name" :value="item.id">
            </el-option>
          </el-select>
          <el-select v-model="city" class="select" clearable @change="cityChange">
            <el-option v-for="item in city_data" :key="item.id" :label="item.name" :value="item.id">
            </el-option>
          </el-select>
          <el-select v-model="area" class="select" clearable @change="zzdAreaChange">
            <el-option v-for="item in area_data" :key="item.id" :label="item.name" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="补充资质照片">
          <el-upload ref="upload" :action="imgurl" list-type="picture-card" :limit="1" :on-progress="uploadingComAdd"
            :file-list="comAddImgList" accept="image/jpg,image/png,image/jpeg" :on-remove="removeImgComAdd"
            :headers="{'token':token}" :on-success="fileSuccessComAdd" :on-error="imgErrorComAdd"
            :on-exceed="limitAlertComAdd" style="height: 145px;">
            <i slot="default" class="el-icon-plus"></i>
          </el-upload>
        </el-form-item>

        <el-form-item label="法人姓名">
          <el-input v-model="formAdd.legal_person_name" style="width: auto;"></el-input>
        </el-form-item>

        <el-form-item label="法人身份证号">
          <el-input v-model="formAdd.legal_person_num" style="width: auto;"></el-input>
        </el-form-item>

        <el-form-item label="身份证有效期">
          <el-date-picker v-model="formAdd.legal_person_validity" value-format="yyyy-MM-dd" type="date"
            placeholder="选择日期"> </el-date-picker>
        </el-form-item>

        <el-form-item label="法人身份证照片">
          <el-upload ref="upload" :action="imgurl" list-type="picture-card" :limit="2" :on-progress="uploadingFr"
            :file-list="frImgList" accept="image/jpg,image/png,image/jpeg" :on-remove="removeImgFr"
            :headers="{'token':token}" :on-success="fileSuccessFr" :on-error="imgErrorFr" :on-exceed="limitAlertFr"
            style="height: 145px;">
            <i slot="default" class="el-icon-plus"></i>
          </el-upload>
        </el-form-item>

        <el-form-item>
          <el-button type="primary" @click="onSubmit(1)">提交</el-button>
          <el-button @click="onSubmit(2)">取消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>

  <el-dialog title="修改门店" :visible.sync="dialogFormVisibleEdit" top="50px" :close-on-click-modal="false">
      <el-form ref="form" :model="formEdit">
        <el-form-item label="门店名称">
          <el-input v-model="formEdit.store_name" placeholder="请填写门店名称" style="width: auto;"></el-input>
        </el-form-item>

        <el-form-item label="所在区县">
          <el-select class="select" v-model="formEdit.area_id" size="medium" placeholder="请选择区" style="width: auto;">
            <el-option v-for="item in area_data_add" :key="item.id" :label="item.name" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="门店位置">
          <map-position @update="mapChangeEdit" :pos="[formEdit.jing_du, formEdit.wei_du]" :address="formEdit.store_addr"  />
        </el-form-item>

        <el-form-item label="详细地址">
          <el-input v-model="formEdit.detail_addr" placeholder="请填写详细地址" style="width: auto;"></el-input>
        </el-form-item>

        <el-form-item label="联系人">
          <el-input v-model="formEdit.contact" placeholder="请填写联系人" style="width: auto;"></el-input>
        </el-form-item>

        <el-form-item label="联系电话">
          <el-input v-model="formEdit.contact_tel" placeholder="请填写联系电话" style="width: auto;"></el-input>
        </el-form-item>

        <el-form-item label="门店照片">
          <el-upload ref="upload" :action="imgurl" list-type="picture-card" :limit="1" :on-progress="uploadingShopEdit"
            :file-list="shopEditList" accept="image/jpg,image/png,image/jpeg" :on-remove="removeImgShopEdit"
            :headers="{'token':token}" :on-success="fileSuccessShopEdit" :on-error="imgErrorShopEdit"
            :on-exceed="limitAlertShopEdit" style="height: 145px;">
            <i slot="default" class="el-icon-plus"></i>
          </el-upload>
        </el-form-item>

        <el-form-item label="营业时间">
          <el-time-select placeholder="开始时间" v-model="formEdit.startTime"  :picker-options="{start: '00:00', step: '00:10', end: '23:50'}"  >
          </el-time-select>
          <el-time-select placeholder="结束时间" v-model="formEdit.endTime" :picker-options="{
            start: '00:00',step: '00:10',end: '23:50'}">
          </el-time-select>
        </el-form-item>

        <el-form-item>
          <el-button type="primary" @click="onSubmit(3)">提交</el-button>
          <el-button @click="onSubmit(4)">取消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>

  </div>
</template>

<script>
  import {
    shopList,
    addStore,
    editStore,
    storeDetail,
    machineSsq,
    storeMechanism
  } from '@/api/agentApi.js'
  import request from '@/api/request.js'
  import MapPosition from '../../components/MapPosition.vue'
  export default {
    components: {
      MapPosition
    },
    data() {
      return { //声明属性变量 可以用this.属性名得到 例如 this.pagesize
        regex: /^1[3456789]\d{9}$/, // 手机号正则
        imgurl: this.$url + '/upload/img', //上传图片请求地址(线上)
        shopInfos: [], // 门店列表
        pagesize: 10, // 每页显示条目个数
        currpage: 1, // 初始页
        CountNum: 0, // 总条数
        loading: false, //加载框
        serchList: {}, //查询列表
        serchListCopy: {}, //查询列表
        shopData: [], //门店下拉
        shopDataCopy: [], //门店下拉
        area_data: [], //区下拉
        area_data_add: [], //区下拉添加自助点时
        dialogFormVisible: false, // 添加门店弹窗
        dialogFormVisibleEdit : false, // 修改门店弹窗
        Addfacility: {
          facility: '' // 添加的设备编号
        },
        file: [], //上传的file数组
        token: localStorage.getItem('token'), //token
        area_id: 0,
        formAdd: {
          store_name: '',
          area_id: '',
          store_addr: '',
          jing_du: '',
          wei_du: '',
          detail_addr: '',
          contact: '',
          contact_tel: '',
          startTime: '',
          endTime: '',
          company_name: '',
          industry: '',
          document_type: '',
          organization_code: '',
          document_validity: '',
          registration: '',
          legal_person_name: '',
          legal_person_num: '',
          legal_person_validity: '',
          province: '',
          city: '',
          area: '',
        }, //添加表单
        formEdit: {
          shop_id:'',
          store_name: '',
          area_id: '',
          store_addr: '',
          jing_du: '',
          wei_du: '',
          detail_addr: '',
          contact: '',
          contact_tel: '',
          startTime: '',
          endTime: '',
        }, //修改表单
        shopImgList: [], //门店图片
        comImgList: [], //证件图片
        comAddImgList: [], //补充证件图片
        frImgList: [], //法人身份证图片
        shopEditList:[],//门店修改图片数组
        province_data: [], // 省
        province: "",
        city_data: [], // 市
        city: "",
        area_data: [], // 区
        area: "",
        hyList: [], //所在行业
        zjType: [] //证件类型
      }
    },
    mounted() {
      this.storeListFun({}, 1) // 获取门店列表
      this.storeListFun2() // 获取门店下拉
      this.areaChange() // 获取区
      this.Ssq() // 获取省
      this.storeMechanismFun() // 获取证件类型 所在行业
    },
    methods: {
      reset() { //重置按钮
        this.serchList = {}
        this.serchListCopy = {}
        this.storeListFun({}, 1)
      },
      InsertTime(row, column) { // 加入时间过滤
        return row.InsertTime
        // return row.InsertTime.slice(0, 16)
      },
      storeListFunSearch(obj, b) { // 搜索功能
        this.serchListCopy = JSON.parse(JSON.stringify(obj)) //深拷贝
        this.storeListFun(obj, 1)
      },
      storeListFun(serchJson, currpage) { // 门店列表
        this.loading = true
        const data = serchJson

        data["page"] = currpage //	当前页数
        data["limit"] = this.pagesize // 每页条数

        shopList(data).then(res => {
          this.shopInfos = res.data.data // 门店列表
          this.CountNum = parseInt(res.data.store_count) // 总条数
          this.currpage = currpage //分页显示当前页
          this.loading = false
        }).catch(err => {
          console.log(err)
        })
      },
      storeListFun2() { // 下拉框获取门店列表
        const data = {}
        data["page"] = 1 //	当前页数
        data["limit"] = 1000 // 每页条数
        shopList(data).then(res => {
          this.shopData = res.data.data.map((item, index) => {
            return Object.assign({}, {
              'shopName': item.shop_name
            }, {
              'id': item.id
            }, {
              'area': item.area
            })
          })

          this.shopData.unshift({
            shopName: '全部门店',
            id: 0,
            area: 0
          })

          this.shopDataCopy = [...this.shopData]

        }).catch(err => {
          console.log(err)
        })
      },
      detail(index, row) { // 点击门店详情
        this.$router.push({
          name: 'ShopList_detail',
          params: {
            Schinfos: row.id
          }
        })
        localStorage.setItem('lxzShopName', row.shop_name) // 存门店名字
      },
      edit(index, row) { // 修改门店
        //console.log(row)
        const datas = {
          shop_id: row.id,
        }
        this.formEdit.shop_id     =  row.id;

        storeDetail(datas).then(res => {

          this.formEdit.store_name     =  res.data.shop_name,
          this.formEdit.area_id        =  res.data.area,
          this.formEdit.store_addr     =  res.data.addr,
          this.formEdit.detail_addr    =  res.data.detail_addr,
          this.formEdit.contact        =  res.data.contact,
          this.formEdit.contact_tel    =  res.data.contact_tel,
          this.formEdit.startTime      =  res.data.open_time,
          this.formEdit.endTime        =  res.data.close_time,
          this.formEdit.jing_du        =  res.data.longitude,
          this.formEdit.wei_du         =  res.data.latitude,

          //图片
          this.shopEditList = [{name:'', url:res.data.pic}]

          this.dialogFormVisibleEdit = true
        }).catch(err => {
          console.log(err)
        })
      },
      handleCurrentChange(val) { // 分页
        this.storeListFun(this.serchListCopy, val)
        // this.currpage = val
      },
      areaChange() {
        const datas = {
          cid: JSON.parse(localStorage.getItem('userInfo')).city,
        }
        machineSsq(datas).then(res => {
          this.area_data = res.data.area;

          this.area_data.unshift({
            id: 0,
            name: '全部地区'
          })
          this.area_data_add = res.data.area.filter(item => item.id != 0)
        }).catch(err => {
          console.log(err)
        })
      },
      areaSelect(e) {
        this.serchList.store_id = '';
        //因为是联动的 所以需要 定一个area_id 用来处理选中完门店 渲染不上去
        this.area_id = this.area_data.find(item => item.id == e)

        if (e == 0) {
          this.shopData = this.shopDataCopy;
        } else {
          this.shopData = this.shopDataCopy;
          this.shopData = this.shopData.filter(item => item.area == e || item.area == 0)
        }
      },
      shopSelect(e) {
        //先清空区下拉 然后赋值渲染
        this.serchList.area_id = ''
        this.serchList.area_id = this.area_id.id
        this.serchList.store_id = e;
      },
      storeMechanismFun() {
        storeMechanism().then(res => {
          this.zjType = res.data.identity
          this.hyList = res.data.options
          // console.log(this.hyList)
        }).catch(err => {
          console.log(err)
        })
      },
      hyChange(e) {
        console.log(e, e[1])
        this.formAdd.industry = e[1];
      },
      Ssq() {
        const data = {
          ssq: 2
        }
        machineSsq(data).then(res => {
          this.province_data = res.data.province // X省
        }).catch(err => {
          console.log(err)
        })
      },
      provincese(val) {

        const aaa = this.province_data.find(item => item.id == val)
        this.formAdd.province = aaa.name

        this.city = '';
        this.area = '';
        if (val != 0) {
          this.citySearch(val)
        }
      },
      citySearch(val) {
        const datas = {
          ssq: 2,
          pid: val,
        }
        machineSsq(datas).then(res => {
          this.city_data = res.data.city;
        }).catch(err => {
          console.log(err)
        })
      },
      cityChange(val) {

        const aaa = this.city_data.find(item => item.id == val)
        this.formAdd.city = aaa.name

        const datas = {
          ssq: 2,
          cid: val,
        }
        this.area = '';
        machineSsq(datas).then(res => {
          this.area_data = res.data.area;
        }).catch(err => {
          console.log(err)
        })
      },
      zzdAreaChange(val) {
        const aaa = this.area_data.find(item => item.id == val)
        this.formAdd.area = aaa.name
      },
      mapChange(posData) {
        this.formAdd.store_addr = posData.addr;
        this.formAdd.jing_du = posData.jing_du;
        this.formAdd.wei_du = posData.wei_du;
      },
      mapChangeEdit(posData) {
        this.formEdit.store_addr = posData.addr;
        this.formEdit.jing_du = posData.jing_du;
        this.formEdit.wei_du = posData.wei_du;
      },
      onSubmit(type) {
        if (type == 1) {
          // console.log(this.formAdd)
          // return
          if (this.formAdd.store_name == "" || this.formAdd.area_id == "" || this.formAdd.store_addr == "" || this
            .formAdd.detail_addr == "" || this.formAdd.contact == "" || this.formAdd.contact_tel == "" || this.formAdd
            .company_name == "" || this.formAdd.industry == "" || this.formAdd.document_type == "" || this.formAdd
            .organization_code == "" || this.formAdd.document_validity == "" || this.province == "" || this.city ==
            "" || this.area == "" || this.formAdd.legal_person_name == "" || this.formAdd.legal_person_num == "" || this
            .formAdd.legal_person_validity == "" || this.formAdd.startTime == "" || this.formAdd.endTime == "") {
            this.$message.error('请补全信息')
            return
          }
          if (!this.regex.test(this.formAdd.contact_tel)) {
            this.$message.error('手机号输入有误')
            return
          }
          if (this.shopImgList.length == 0) {
            this.$message.error('请上传门店照片')
            return
          }
          if (this.comImgList.length == 0) {
            this.$message.error('请上传证件照片')
            return
          }
          if (this.frImgList.length < 2) {
            this.$message.error('请补齐法人身份证正反面照片')
            return
          }

          const data = {
            store_name: this.formAdd.store_name,
            area_id: this.formAdd.area_id,
            store_addr: this.formAdd.store_addr,
            longitude: this.formAdd.jing_du,
            latitude: this.formAdd.wei_du,
            detail_addr: this.formAdd.detail_addr,
            contact: this.formAdd.contact,
            contact_tel: this.formAdd.contact_tel,
            pic: this.shopImgList[0].url,
            business_hours: this.formAdd.startTime+"-"+this.formAdd.endTime,

            company_name: this.formAdd.company_name,
            industry: this.formAdd.industry,
            document_type: this.formAdd.document_type,
            document_photo: this.comImgList[0].url,
            organization_code: this.formAdd.organization_code,
            document_validity: this.formAdd.document_validity,
            registration: this.formAdd.province + this.formAdd.city + this.formAdd.area,
            add_document_photo: this.comAddImgList.length != 0 ? this.comAddImgList[0].url : '',

            legal_person_name: this.formAdd.legal_person_name,
            legal_person_num: this.formAdd.legal_person_num,
            legal_person_validity: this.formAdd.legal_person_validity,
            legal_person_photo_upper: this.frImgList[0].url,
            legal_person_photo_lower: this.frImgList[1].url
          }

          addStore(data).then(res => {
            // console.log(res)
            if (res.status.code == 1) {
              this.$message.success('提交成功 请等待审核')
              this.dialogFormVisible = false;

              this.formAdd = {
                store_name: '',
                area_id: '',
                store_addr: '',
                detail_addr: '',
                contact: '',
                contact_tel: '',
                company_name: '',
                industry: '',
                document_type: '',
                organization_code: '',
                document_validity: '',
                registration: '',
                legal_person_name: '',
                legal_person_num: '',
                legal_person_validity: '',
                province: '',
                city: '',
                area: '',
              }; //表单

              this.province = "";
              this.city = "";
              this.area = "";
              this.shopImgList = [];
              this.comImgList = [];
              this.comAddImgList = [];
              this.frImgList = [];

            } else {
              this.$message.error(res.status.msg)
            }
          }).catch(err => {
            console.log(err)
          })

        } else if(type==2){
          this.dialogFormVisible = false;
        }else if(type==3){


          if (this.formEdit.store_name == "" || this.formEdit.area_id == "" || this.formEdit.store_addr == "" || this
            .formEdit.detail_addr == "" || this.formEdit.contact == "" || this.formEdit.contact_tel == "" ||  this.formEdit.startTime == "" || this.formEdit.endTime == "") {
            this.$message.error('请补全信息')
            return
          }
          if (!this.regex.test(this.formEdit.contact_tel)) {
            this.$message.error('手机号输入有误')
            return
          }
          if (this.shopEditList.length == 0) {
            this.$message.error('请上传门店照片')
            return
          }

          const data = {
            shop_id: this.formEdit.shop_id,
            store_name: this.formEdit.store_name,
            area: this.formEdit.area_id,
            store_addr: this.formEdit.store_addr,
            longitude: this.formEdit.jing_du,
            latitude: this.formEdit.wei_du,
            detail_addr: this.formEdit.detail_addr,
            contact: this.formEdit.contact,
            contact_tel: this.formEdit.contact_tel,
            pic: this.shopEditList[0].url,
            business_hours: this.formEdit.startTime+"-"+this.formEdit.endTime,
          }
 
          editStore(data).then(res => {
            if (res.status.code == 1) {
              this.$message.success('修改成功')
              this.dialogFormVisibleEdit = false;
              this.storeListFun(this.serchListCopy, this.currpage)
            }
          }).catch(err => {
            console.log(err)
          })

        }else if(type==4){
          this.dialogFormVisibleEdit = false;
        }
      },
      uploadingShop(event, file, fileList) { //判断用户上传文件类型和大小
        if (file.raw.type == 'image/jpeg' || file.raw.type == 'image/jpg' || file.raw.type == 'image/png') {
          const size = file.size / 1024
          if (size > 1024) {
            this.$message.error('图片大小不能超过1M！')
            this.$refs.upload.abort() //取消上传
            this.shopImgList = fileList.filter(item => item.uid != file.uid)
          }
        } else {
          this.$message.error("只可以上传jpeg、jpg和png格式图片，请重新上传！")
          this.shopImgList = fileList.filter(item => item.uid != file.uid)
          this.$refs.upload.abort() //取消上传
        }
      },
      limitAlertShop() { //展示图片超过张数提醒
        this.$message.error('最多能上传1张图片！')
      },
      imgErrorShop(err, file, fileList) { //展示图片上传失败提醒
        this.$message.error('图片上传失败！')
      },
      removeImgShop(file, fileList) { //展示图片删除提醒
        this.$message.success('图片删除成功！')
        this.shopImgList = this.shopImgList.filter(item => item.imgId != file
          .imgId) //从shopImgList 过滤掉 item.imgId 不等于 file.uid
      },
      fileSuccessShop(response, file, fileList) { //展示图片上传成功
        this.$message.success('图片上传成功！')
        let data = {
          imgId: file.uid,
          url: response.data.url
        }
        this.shopImgList.push(data); //把上传成功的返回的地址（一般是oss地址） 赋值给shopImgList

      },
      uploadingCom(event, file, fileList) { //判断用户上传文件类型和大小
        if (file.raw.type == 'image/jpeg' || file.raw.type == 'image/jpg' || file.raw.type == 'image/png') {
          const size = file.size / 1024
          if (size > 1024) {
            this.$message.error('图片大小不能超过1M！')
            this.$refs.upload.abort() //取消上传
            this.comImgList = fileList.filter(item => item.uid != file.uid)
          }
        } else {
          this.$message.error("只可以上传jpeg、jpg和png格式图片，请重新上传！")
          this.comImgList = fileList.filter(item => item.uid != file.uid)
          this.$refs.upload.abort() //取消上传
        }
      },
      limitAlertCom() { //展示图片超过张数提醒
        this.$message.error('最多能上传1张图片！')
      },
      imgErrorCom(err, file, fileList) { //展示图片上传失败提醒
        this.$message.error('图片上传失败！')
      },
      removeImgCom(file, fileList) { //展示图片删除提醒
        this.$message.success('图片删除成功！')
        this.comImgList = this.comImgList.filter(item => item.imgId != file
          .imgId) //从comImgList 过滤掉 item.imgId 不等于 file.uid
      },
      fileSuccessCom(response, file, fileList) { //展示图片上传成功
        this.$message.success('图片上传成功！')
        let data = {
          imgId: file.uid,
          url: response.data.url
        }
        this.comImgList.push(data); //把上传成功的返回的地址（一般是oss地址） 赋值给comImgList

      },
      uploadingComAdd(event, file, fileList) { //判断用户上传文件类型和大小
        if (file.raw.type == 'image/jpeg' || file.raw.type == 'image/jpg' || file.raw.type == 'image/png') {
          const size = file.size / 1024
          if (size > 1024) {
            this.$message.error('图片大小不能超过1M！')
            this.$refs.upload.abort() //取消上传
            this.comAddImgList = fileList.filter(item => item.uid != file.uid)
          }
        } else {
          this.$message.error("只可以上传jpeg、jpg和png格式图片，请重新上传！")
          this.comAddImgList = fileList.filter(item => item.uid != file.uid)
          this.$refs.upload.abort() //取消上传
        }
      },
      limitAlertComAdd() { //展示图片超过张数提醒
        this.$message.error('最多能上传1张图片！')
      },
      imgErrorComAdd(err, file, fileList) { //展示图片上传失败提醒
        this.$message.error('图片上传失败！')
      },
      removeImgComAdd(file, fileList) { //展示图片删除提醒
        this.$message.success('图片删除成功！')
        this.comAddImgList = this.comAddImgList.filter(item => item.imgId != file
          .imgId) //从comAddImgList 过滤掉 item.imgId 不等于 file.uid
      },
      fileSuccessComAdd(response, file, fileList) { //展示图片上传成功
        this.$message.success('图片上传成功！')
        let data = {
          imgId: file.uid,
          url: response.data.url
        }
        this.comAddImgList.push(data); //把上传成功的返回的地址（一般是oss地址） 赋值给comAddImgList

      },
      uploadingFr(event, file, fileList) { //判断用户上传文件类型和大小
        if (file.raw.type == 'image/jpeg' || file.raw.type == 'image/jpg' || file.raw.type == 'image/png') {
          const size = file.size / 1024
          if (size > 1024) {
            this.$message.error('图片大小不能超过1M！')
            this.$refs.upload.abort() //取消上传
            this.frImgList = fileList.filter(item => item.uid != file.uid)
          }
        } else {
          this.$message.error("只可以上传jpeg、jpg和png格式图片，请重新上传！")
          this.frImgList = fileList.filter(item => item.uid != file.uid)
          this.$refs.upload.abort() //取消上传
        }
      },
      limitAlertFr() { //展示图片超过张数提醒
        this.$message.error('最多能上传2张图片！')
      },
      imgErrorFr(err, file, fileList) { //展示图片上传失败提醒
        this.$message.error('图片上传失败！')
      },
      removeImgFr(file, fileList) { //展示图片删除提醒
        this.$message.success('图片删除成功！')
        this.frImgList = this.frImgList.filter(item => item.imgId != file
          .imgId) //从frImgList 过滤掉 item.imgId 不等于 file.uid
      },
      fileSuccessFr(response, file, fileList) { //展示图片上传成功
        this.$message.success('图片上传成功！')
        let data = {
          imgId: file.uid,
          url: response.data.url
        }
        this.frImgList.push(data); //把上传成功的返回的地址（一般是oss地址） 赋值给dldList
      },
      uploadingShopEdit(event, file, fileList) { //判断用户上传文件类型和大小
        if (file.raw.type == 'image/jpeg' || file.raw.type == 'image/jpg' || file.raw.type == 'image/png') {
          const size = file.size / 1024
          if (size > 1024) {
            this.$message.error('图片大小不能超过1M！')
            this.$refs.upload.abort() //取消上传
            this.shopEditList = fileList.filter(item => item.uid != file.uid)
          }
        } else {
          this.$message.error("只可以上传jpeg、jpg和png格式图片，请重新上传！")
          this.shopEditList = fileList.filter(item => item.uid != file.uid)
          this.$refs.upload.abort() //取消上传
        }
      },
      limitAlertShopEdit() { //展示图片超过张数提醒
        this.$message.error('最多能上传1张图片！')
      },
      imgErrorShopEdit(err, file, fileList) { //展示图片上传失败提醒
        this.$message.error('图片上传失败！')
      },
      removeImgShopEdit(file, fileList) { //展示图片删除提醒
        this.$message.success('图片删除成功！')
        this.shopEditList = this.shopEditList.filter(item => item.imgId != file
          .imgId) //从shopEditList 过滤掉 item.imgId 不等于 file.uid
      },
      fileSuccessShopEdit(response, file, fileList) { //展示图片上传成功
        this.$message.success('图片上传成功！')
        let data = {
          imgId: file.uid,
          url: response.data.url
        }
        this.shopEditList.push(data); //把上传成功的返回的地址（一般是oss地址） 赋值给shopEditList
      },
    }
  }
</script>

<style lang="scss" scoped>
  #body {
    // height: 100%;
    background: #f0f2f5;
  }

  .fatherBox {
    width: 98%;
    // height: 800px;
    display: flex;
    background: white;
    margin: 0 auto;
    flex-direction: column;
    justify-content: center;

    .headerBox {
      display: flex;
      height: 52px;
      width: 98%;
      align-items: center;
      justify-content: space-between;
      border-bottom: 2px solid #e9e9e9;

      p {
        font-size: 16px;
        font-family: 萍方-简;
        font-weight: normal;
        color: #000000;
        margin-left: 32px;
      }

      .btn {
        display: flex;
        align-items: center;
        width: 109px;
        height: 32px;
        opacity: 1;
        border-radius: 2px;
      }
    }

    .query {
      width: 98%;
      align-items: center;
      display: flex;
      justify-content: space-between;
      padding-top: 20px;
      padding-left: 32px;

      .queryLeft {
        width: 75%;
        display: flex;

        div {
          width: 22%;
          text-align: left;

          .input,
          .select {
            width: 60%;
          }
        }
      }
    }
  }
</style>
